import React, { FC } from 'react';
// import config from 'app/core/config';
import { Icon, IconName, useTheme } from '@grafana/ui';

export interface FooterLink {
  text: string;
  icon?: string;
  url?: string;
  target?: string;
}

export let getFooterLinks = (): FooterLink[] => {
  return [
    {
      text: 'Dispatch',
      icon: 'home-alt',
      url: 'https://dispatchintegration.com/',
      target: '_blank',
    },
    {
      text: 'Support',
      icon: 'comments-alt',
      url: 'https://dispatchintegration.freshdesk.com/support/home',
      target: '_blank',
    },
    {
      text: 'Privacy Policy',
      icon: 'book-open',
      url: 'https://dispatchintegration.com/privacy-policy/',
      target: '_blank',
    },
  ];
};

export let getVersionLinks = (): FooterLink[] => {
  // const { buildInfo, licenseInfo } = config;
  const links: FooterLink[] = [];
  // const stateInfo = licenseInfo.stateInfo ? ` (${licenseInfo.stateInfo})` : '';

  // links.push({ text: `${buildInfo.edition}${stateInfo}`, url: licenseInfo.licenseUrl });

  // if (buildInfo.hideVersion) {
  //   return links;
  // }

  // links.push({ text: `v${buildInfo.version} (${buildInfo.commit})` });

  // if (buildInfo.hasUpdate) {
  //   links.push({
  //     text: `New version available!`,
  //     icon: 'download-alt',
  //     url: 'https://grafana.com/grafana/download?utm_source=grafana_footer',
  //     target: '_blank',
  //   });
  // }

  return links;
};

export function setFooterLinksFn(fn: typeof getFooterLinks) {
  getFooterLinks = fn;
}

export function setVersionLinkFn(fn: typeof getFooterLinks) {
  getVersionLinks = fn;
}

export const Footer: FC = React.memo(() => {
  const links = getFooterLinks().concat(getVersionLinks());

  const _theme = useTheme();

  return (
    <footer className="footer">
      <div className="text-center">
        <ul>
          {links.map((link) => (
            <li key={link.text}>
              <a
                href={link.url}
                target={link.target}
                rel="noopener"
                style={{ color: _theme.isLight ? '#464c54' : '#f2f3f4' }}
              >
                {link.icon && <Icon name={link.icon as IconName} />} {link.text}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </footer>
  );
});

Footer.displayName = 'Footer';
