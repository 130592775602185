import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Organization, OrganizationState, OrgUserDetail, OrgUserDetailState } from 'app/types';

// OrganizationState

export const initialOrganizationState: OrganizationState = {
  organization: {} as Organization,
};

const organizationSlice = createSlice({
  name: 'organization',
  initialState: initialOrganizationState,
  reducers: {
    organizationLoaded: (state, action: PayloadAction<Organization>): OrganizationState => {
      return { ...state, organization: action.payload };
    },
    setOrganizationName: (state, action: PayloadAction<string>): OrganizationState => {
      return { ...state, organization: { ...state.organization, name: action.payload } };
    },
  },
});

export const { setOrganizationName, organizationLoaded } = organizationSlice.actions;
export const organizationReducer = organizationSlice.reducer;

// OrgUserDetailState

export const initialOrgUserDetailState: OrgUserDetailState = {
  user: undefined,
  isLoading: true,
};

const orgUserDetailSlice = createSlice({
  name: 'orgUserDetail',
  initialState: initialOrgUserDetailState,
  reducers: {
    orgUserDetailLoadedAction: (state, action: PayloadAction<OrgUserDetail>): OrgUserDetailState => {
      return { ...state, user: action.payload };
    },
    orgUserEditPageLoadedAction: (state, action: PayloadAction<boolean>): OrgUserDetailState => {
      return { ...state, isLoading: !action.payload };
    },
  },
});

export const { orgUserDetailLoadedAction, orgUserEditPageLoadedAction } = orgUserDetailSlice.actions;
export const orgUserDetailReducer = orgUserDetailSlice.reducer;

export default {
  organization: organizationReducer,
  orgUserDetail: orgUserDetailReducer,
};
