import React, { FC } from 'react';
import { css, cx } from 'emotion';

export interface BrandComponentProps {
  className?: string;
  children?: JSX.Element | JSX.Element[];
}

const LoginLogo: FC<BrandComponentProps> = ({ className }) => {
  return (
    <div
      draggable={false}
      style={{ userSelect: 'none', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <img
        draggable={false}
        style={{ userSelect: 'none', marginBottom: '20px' }}
        src="public/img/dispatch_sentinel_logo.png"
        alt="Dispatch Sentinel"
        width="130px"
      />
      <img
        draggable={false}
        style={{ userSelect: 'none' }}
        src="public/img/dispatch_sentinel_logo_wordmark.png"
        alt="Dispatch Sentinel"
        width="240px"
      />
    </div>
  );
};

const LoginBackground: FC<BrandComponentProps> = ({ className, children }) => {
  const background = css`
    background: url(public/img/dispatch_sentinel_login_background.png);
    background-size: cover;
  `;

  return <div className={cx(background, className)}>{children}</div>;
};

const MenuLogo: FC<BrandComponentProps> = ({ className }) => {
  return <img className={className} src="public/img/dispatch_sentinel_logo.png" alt="Dispatch Sentinel" />;
};

const LoginBoxBackground = () => {
  return css`
    background: rgba(0, 0, 0, 0.85);
    background-size: cover;
  `;
};

export class Branding {
  static LoginLogo = LoginLogo;
  static LoginBackground = LoginBackground;
  static MenuLogo = MenuLogo;
  static LoginBoxBackground = LoginBoxBackground;
  static AppTitle = 'Dispatch Sentinel';
  static LoginTitle = '';
  static GetLoginSubTitle = () => {
    const slogans = [
      // "Don't get in the way of the data",
      // 'Your single pane of glass',
      // 'Built better together',
      // 'Democratising data',
      '',
    ];
    const count = slogans.length;
    return slogans[Math.floor(Math.random() * count)];
  };
}
